import React from "react"
import { Link, graphql, StaticQuery } from "gatsby";
import BlogEntryMetadata from "../components/BlogEntryMetadata";
import PostTags from "../components/PostTags";
import "../styles/BlogEntryPreview.scss";
const _ = require(`lodash`)

class BlogEntryPreview extends React.Component {
  render() {
    /* Consumers of BlogEntryPreview must make an AllMarkdownRemark query on the BlogEntryPreview fragment exported below.
    query {
      allMarkdownRemark {
        ...BlogEntryPreview
      }
    }

    If refactoring in the future see if we can change this to a parameterized static query if the feature proposed
    here (https://github.com/gatsbyjs/gatsby/issues/10482) has been implemented.
    */
    const { node: postNode } = this.props;
    const title = postNode.frontmatter.title || postNode.fields.slug
    const tagList = postNode.frontmatter.tags;
    return (

      <StaticQuery
        query={authorsQuery}
        render={data => {
          /*This is super gross but I can't find a documented way to create many-to-many
          relationships  between gatsby markdownRemark nodes, which is what there should be
          between blog posts and authors. Nor can I find a way to chain graphQl queries, which would
          also be better. */
          const authorNode = data.allMarkdownRemark.edges.map(edge => edge.node)
          .find(node => node.fields.slug === `/${postNode.frontmatter.author}/`);

          return (
            <div className="blog-entry-preview">
              <h2 className="blog-entry-preview__title">
                <Link to={`blog${postNode.fields.slug}`}>
                  {title}
                </Link>
              </h2>
              <BlogEntryMetadata
                author={authorNode.frontmatter.name}
                date={postNode.frontmatter.date}
              />
              <p className="blog-entry-preview__teaser" dangerouslySetInnerHTML={{ __html: postNode.excerpt }} />
              <PostTags tagList={tagList} />
            </div>
          )
        }}
      />
    );
  }
}

export default BlogEntryPreview;

export const query = graphql`
  fragment BlogEntryPreview on MarkdownRemarkConnection {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          author
          tags
        }
      }
    }
  }
`;

const authorsQuery = graphql`
  query {
    allMarkdownRemark(filter: { collection: { eq: "authors" } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
          }
        }
      }
    }
  }
`;