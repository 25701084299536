import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import "../styles/TagsNavigation.scss";
const _ = require(`lodash`)


class TagsNavigation extends React.Component {
  render() {
    return (
      <StaticQuery
        query={tagsQuery}
        render={data => {
          const tags = data.allMarkdownRemark.group;
          return (
            <ul className="tags-navigation">
              {tags.map((tag) => {
                return (<li key={tag.fieldValue}>
                  <Link to={`/blog/tags/${_.kebabCase(tag.fieldValue)}`}>
                    {tag.fieldValue}
                    ({tag.totalCount})
                  </Link>
                </li>)
              })}
            </ul>
          )
        }}
      />
    )
  }
}

export default TagsNavigation

const tagsQuery = graphql`
  query tagQuery {
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
