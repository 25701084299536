import React from "react"
import { Link } from "gatsby";
import "../styles/BackToBlog.scss";

function BackToBlog() {
  return (
    <Link to="blog" className="back-to-blog">
      ← Back to All Blog Posts
    </Link>
)}

export default BackToBlog;