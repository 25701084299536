import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageContent from "../components/PageContent";
import BlogEntryPreview from "../components/BlogEntryPreview"
import TagsNavigation from "../components/TagsNavigation";
import BackToBlog from "../components/BackToBlog";


class BlogAuthor extends React.Component {
  render() {
    const { data } = this.props;
    const pageName = "blog";
    const pageTitle = `Tagged with: ${this.props.location.pathname.split("/").splice(-1).pop()}`;

    if (data.allMarkdownRemark) {
      const posts = data.allMarkdownRemark.edges;
      return (
        <Layout location={this.props.location} title={pageTitle}>
          <SEO
            title={pageTitle}
            keywords={[`blog`, `gatsby`, `javascript`, `react`]}
          />
          <PageContent>
            <div className="blog-tag">
              {posts.map(({ node }) => {
                return (
                  <BlogEntryPreview key={node.fields.slug} node={node} />
                )
              })}
              <TagsNavigation />
              <BackToBlog />
            </div>
          </PageContent>
        </Layout>
      )
    } else {
      return (
        <Layout location={this.props.location} title={pageTitle}>
          <SEO
            title={pageTitle}
            keywords={[`blog`, `gatsby`, `javascript`, `react`]}
          />
          <PageContent>
            <h3 className="blog-tag-none-found">
              This person has no blog posts!
            </h3>
          </PageContent>
        </Layout>
      )
    }
  }
}

export default BlogAuthor

export const pageQuery = graphql`
  query BlogPostsByAuthor($author: String!){
    allMarkdownRemark(filter: { collection: { eq: "blog" }, frontmatter: { author: { in: [$author] } } },
                      sort: { fields: [frontmatter___date], order: DESC }) {
      ...BlogEntryPreview
    }
  }
`
